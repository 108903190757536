import WalletConnectProvider from "@walletconnect/web3-provider";
import { useState } from "react";
import { toast } from "react-toastify";
import Web3Modal from "web3modal";
import { ethers } from "ethers";
import { getModuleFactory, signMetamask } from "./myriaCoreSDK";
import { MyriaClient, Networks, UserDataResponse } from "myria-core-sdk";
import { getMyriaClient } from "../samples/common/myria-client";

export const changeNetwork = async (provider: any, ENV_CHAIN_ID: number) => {
  const chainId = ENV_CHAIN_ID;
  const changeIdHex = "0x" + chainId.toString(16);
  try {
    await provider?.request({
      method: "wallet_switchEthereumChain",
      params: [
        {
          chainId: changeIdHex,
        },
      ],
    });
  } catch (err: any) {
    // This error code indicates that the chain has not been added to MetaMask.
    if (err.code === 4902) {
      await provider?.request({
        method: "wallet_addEthereumChain",
        params: [
          {
            chainId: changeIdHex,
          },
        ],
      });
    } else {
      throw err;
    }
  }
};

const getProviderOptions = () => ({
  walletconnect: {
    package: WalletConnectProvider,
    options: {
      infuraId: process.env.REACT_APP_INFURA_ID,
    },
  },
});

export enum NetworkID {
  MAINNET = Networks.MAINNET,
  GOERLI = Networks.GOERLI,
  SEPOLIA = Networks.SEPOLIA
}

const useMetamask = () => {
  const [isConnected, setIsConnected] = useState(false);
  const [walletAdrress, setWalletAddress] = useState("0x");
  const [starkKey, setStarkKey] = useState("0x");
  const [myriaClient, setMyriaClient] = useState<MyriaClient>(null);
  const [netWorkIdUser, setNetWorkIdByUser] = useState<NetworkID>(NetworkID.MAINNET);
  const checkIfMetaMaskInstalled = () => {
    const { ethereum } = window;
    if (Boolean(ethereum && ethereum.isMetaMask)) {
      return true;
    } else {
      return false;
    }
  };

  const loginL2Wallet = async (
    metamaskAccount: string
  ): Promise<UserDataResponse | undefined> => {
    const moduleFactory = await getModuleFactory();
    if (!moduleFactory) return;
    const userModule = moduleFactory.getUserManager();
    try {
      const user = await userModule.getUserByWalletAddress(metamaskAccount);
      if (
        user &&
        user?.ethAddress?.toLowerCase() === metamaskAccount?.toLowerCase()
      ) {
        return user;
      } else {
        return undefined;
      }
    } catch {
      toast(
        "Login Failed, This Account is not existed. Please register on the Myria website.",
        {
          type: "error",
        }
      );
      return undefined;
    }
  };

  const checkIfAlreadyConnected = async () => {
    const { ethereum } = window;
    if (ethereum && ethereum.isMetaMask) {
      const accounts = await ethereum.request({ method: 'eth_accounts' });
      if (accounts && accounts.length > 0) {
        // MetaMask is already connected with an account
        const currentAccount = accounts[0];
        return currentAccount;
      }
    }
    return null;
  };

  async function connectL2Wallet(
    successCallback: (walletAddress: string, starkKey: string) => void,
    closeCallback?: () => void
  ) {
    try {
      const alreadyConnectedAccount = await checkIfAlreadyConnected();
  
      if (alreadyConnectedAccount) {
        // Proceed with login flow using the saved account
        const currentAccount = alreadyConnectedAccount;
        const moduleFactory = await getModuleFactory();
        if (!moduleFactory) return;
  
        const commonModule = moduleFactory.getCommonModule();
        const getStarkKey = await commonModule.generateStarkKey(currentAccount);
        const statusLoginL2Wallet = await loginL2Wallet(currentAccount);
  
        if (
          statusLoginL2Wallet &&
          "0x" + getStarkKey === statusLoginL2Wallet.starkKey
        ) {
          toast("Login Success", { type: "success" });
          setIsConnected(true);
          const sampleClient = await getMyriaClient(true);
          setWalletAddress(statusLoginL2Wallet.ethAddress);
          setStarkKey(statusLoginL2Wallet.starkKey);
          setMyriaClient(sampleClient);
          successCallback(
            statusLoginL2Wallet.ethAddress,
            statusLoginL2Wallet.starkKey
          );
        } else {
          toast("Login failed, The stark key is mismatch.", { type: "error" });
        }
      } else {
        // Show Web3Modal if no account is already connected
        const tWebModal = new Web3Modal({
          network: netWorkIdUser === NetworkID.MAINNET ? "mainnet" : "sepolia",
          cacheProvider: false, // Disable provider cache
          providerOptions: getProviderOptions(),
        });
  
        const provider = await tWebModal.connect();
        const providerApi = new ethers.providers.Web3Provider(provider);
        const network = await providerApi.getNetwork();
  
        if (network.chainId !== netWorkIdUser) {
          await changeNetwork(provider, netWorkIdUser);
        }
  
        const accounts = await providerApi.listAccounts();
        const currentAccount = accounts[0];
        // Continue the flow with the newly connected account
        const moduleFactory = await getModuleFactory();
        if (!moduleFactory) return;
  
        const commonModule = moduleFactory.getCommonModule();
        const getStarkKey = await commonModule.generateStarkKey(currentAccount);
        const statusLoginL2Wallet = await loginL2Wallet(currentAccount);
  
        if (
          statusLoginL2Wallet &&
          "0x" + getStarkKey === statusLoginL2Wallet.starkKey
        ) {
          toast("Login Success", { type: "success" });
          setIsConnected(true);
          const sampleClient = await getMyriaClient(true);
          setWalletAddress(statusLoginL2Wallet.ethAddress);
          setStarkKey(statusLoginL2Wallet.starkKey);
          setMyriaClient(sampleClient);
          successCallback(
            statusLoginL2Wallet.ethAddress,
            statusLoginL2Wallet.starkKey
          );
        }
      }
    } catch (error: any) {
      if (error.code === 4001) {
        toast("MetaMask connection request was rejected. Please try again.", {
          type: "error",
        });
      } else {
        toast("An error occurred while connecting. Please try again.", {
          type: "error",
        });
      }
      console.error("Error during wallet connection:", error);
  
      if (closeCallback) {
        closeCallback();
      }
    }
  }
  
  

  async function disconnectL2Wallet() {
    setIsConnected(false);
    setWalletAddress("0x");
    setStarkKey("0x");
  }

  return {
    connectL2Wallet,
    isConnected,
    walletAdrress,
    starkKey,
    myriaClient,
    netWorkIdUser,
    setNetWorkIdByUser,
  };
};

export default useMetamask;
